import * as amplitude from '@amplitude/analytics-browser'

export const pageViewTrackingEnrichment = () => {
  return {
    name: 'page-view-tracking-enrichment',
    type: 'enrichment',
    execute: async (event) => {
      const currentSessionId = event.session_id
      const isPDPandHasSizes = window?.initialState?.adobeRecord?.otherSizes
      const isPDPandHasItemNumber = document.querySelector('[data-cnstrc-product-detail][data-cnstrc-item-id]')?.dataset?.cnstrcItemId

      if (document.referrer) {
        event.event_properties.PreviousPagePath = (new URL(document.referrer)).pathname
      }

      if (isPDPandHasSizes) {
        event.event_properties.pdpVariantItems = isPDPandHasSizes.map(size => size.productPartNumber)
      }

      if (isPDPandHasItemNumber) {
        event.event_properties.pdpItemNumber = isPDPandHasItemNumber
      }

      if (event.event_type === '[Amplitude] Page Viewed' && window?.sourceCodeObject?.changed === true) {
        event.user_properties = {
          ...event.user_properties,
          SourceCode: window.sourceCodeObject.sourceCode
        }
      }

      // hotfix for Amplitude bug with excludeReferrers logic
      if (event.event_type === '[Amplitude] Page Viewed' && event.event_properties.referring_domain === window.location.host) {
        delete event.event_properties.referring_domain
        delete event.event_properties.referrer
      }

      if (currentSessionId) {
        const AMPSessionId = localStorage.getItem('AMP_session_id')
        if (AMPSessionId !== currentSessionId.toString()) {
          localStorage.setItem('AMP_session_id', currentSessionId)
          event.user_properties = {
            ...event.user_properties,
            EntryPage: new URL(window.location.href).pathname,
            ScreenResolution: {
              width: window.screen.width,
              height: window.screen.height
            }
          }
        }
      }

      return event
    }
  }
}
let amplitudeInitialized = false
export const initAmplitude = () => {
  if (!amplitudeInitialized && typeof window.amplitude === 'undefined' && typeof document !== 'undefined') {
    const instance = amplitude.createInstance()
    instance.add(pageViewTrackingEnrichment())
    instance.init(window.amplitudeApiKey, undefined, {
      transport: 'beacon',
      defaultTracking: {
        pageViews: true,
        sessions: false,
        fileDownloads: false,
        formInteractions: false,
        attribution: {
          excludeReferrers: [
            '1centineltest.cardinalcommerce.com',
            'centineltest.cardinalcommerce.com',
            'cardinalcommerce.com',
            'sandbox.paypal.com',
            'apay-us.amazon.com',
            'paypal.com',
            'hosted-pages.id.me',
            window.location.host
          ]
        }
      }
    })

    window.amplitude = instance
    amplitudeInitialized = true
  }
}

initAmplitude()

if (
  typeof window.amplitudeAssetsHandler === 'undefined' &&
  typeof document !== 'undefined'
) {
  // eslint-disable-next-line no-inner-declarations
  function trackImageClicks () {
    const container = document.querySelector('body')

    container.addEventListener('click', function (event) {
      const target = event.target

      if (target.tagName === 'IMG') {
        const alt = target.getAttribute('alt')
        const src = target.getAttribute('src')
        const assetName = src.split('/').pop()
        const parentArea = target.closest(
          '[data-site-area="header"], [data-site-area="footer"], [data-site-area="left-navigation"], [data-site-area="top-navigation"], [data-site-area="body"]'
        )
        const pagePosition = parentArea
          ? parentArea.getAttribute('data-site-area')
          : 'undefined'

        const mapAreas = {
          header: 'Header',
          footer: 'Footer',
          'left-navigation': 'Left Navigation',
          'top-navigation': 'Top Navigation',
          body: 'Body'
        }

        window.amplitude.track('asset clicked', {
          AssetName: assetName,
          Alt: alt,
          PageName: new URL(window.location.href).pathname,
          PagePosition: mapAreas[pagePosition]
        })
      }
    })
  }

  document.addEventListener('DOMContentLoaded', trackImageClicks)

  window.amplitudeAssetsHandler = true
}

import styled, { css } from 'styled-components'
import React, { useEffect, useState } from 'react'
import { Cart } from '../../_svgs/Cart/Cart'
import { TransparentButton } from '../../_inputs/Button/Button'
import { HamburgerMenu } from '../../_svgs/HamburgerMenu/HamburgerMenu'
import { HeartNoFill } from '../../_svgs/HeartNoFill/HeartNoFill'
import { QuestionWithCircle } from '../../_svgs/QuestionWithCircle/QuestionWithCircle'
import { PersonWithCircle } from '../../_svgs/PersonWithCircle/PersonWithCircle'
import { HeaderMenuDropdown } from '../HeaderMenuDropdown/HeaderMenuDropdown'
import { useDispatch, useSelector } from 'react-redux'
import { SET_SHOW_OVERLAY, TRIGGER_CLOSE_DROPDOWN } from '../../../constants/headerConstants'
import { useMediaQuery } from 'react-responsive'
import { sharedSetInstanceAction } from '../../../actions/sharedActions'

export const ReactHeaderFixedBottom = ({
  cartCount = 0,
  emailOpenHandler = () => {}
}) => {
  const dispatch = useDispatch()
  const [showMenuType, setShowMenuType] = useState(0)
  const isLoggedIn = useSelector(state => state.header?.userStatus ? state.header?.userStatus : false)
  const triggerClose = useSelector(state => state.header?.triggerCloseDropdown ? state.header.triggerCloseDropdown : false)
  const isDesktop = useMediaQuery({ minWidth: 1280 })

  const triggerDropdown = (menuIndex, eventType) => {
    if (menuIndex === showMenuType && eventType !== 'hover') {
      closeHandler()
    } else {
      if (typeof window.location !== 'undefined') {
        document.body.style.overflow = 'hidden'
        document.body.style.position = 'fixed'
      }
      setShowMenuType(menuIndex)
      dispatch({type: SET_SHOW_OVERLAY, payload: true})
    }
  }

  const closeHandler = () => {
    if (typeof window.location !== 'undefined') {
      document.body.style.overflow = 'auto'
      document.body.style.position = 'initial'
    }
    setShowMenuType(0)
    dispatch({ type: SET_SHOW_OVERLAY, payload: false })
  }

  useEffect(() => {
    if (triggerClose) {
      closeHandler()
      dispatch({ type: TRIGGER_CLOSE_DROPDOWN, payload: false })
      if (typeof window.location !== 'undefined') {
        document.body.style.overflow = 'auto'
        document.body.style.position = 'initial'
      }
    }
  }, [triggerClose])

  const loginSliderHandler = () => {
    dispatch(sharedSetInstanceAction({
      name: 'slideInLogin',
      data: {}
    }))
  }

  return (
    <ReactHeaderFixedBottomContainer data-testid='react-header-fixed-btm'>
      <ReactHeaderFixedBottomRelative>
        <ReactHeaderFixedDiv styles={css`padding: 0.4rem 0 0.3rem 0;`}>
          <ReactHeaderFixedBtnContainer>
            <ReactHeaderFixedBottomBtnDiv styles={css`padding-left: 2rem;`}>
              <ReactHeaderFixedBottomBtn
                aria-label='category hamburger menu'
                data-testid='categoryHamMenu'
                onClick={() => {triggerDropdown(4)}}
                onMouseEnter={isDesktop ? () => {triggerDropdown(4, 'hover')} : () => {}}
                styles={css`
                  width: 52px;
                  &:hover {
                    path {
                      fill: ${props => props.theme.buttercupYellow};
                    }
                  }
                `}
              >
                <HamburgerMenu/>
              </ReactHeaderFixedBottomBtn>
            </ReactHeaderFixedBottomBtnDiv>
            <ReactHeaderFixedBottomBtnDiv>
              <ReactHeaderA
                aria-label='favorites'
                data-tracking='header|favorites'
                href='/myaccount/favorites.html'
                styles={css`
                  &:hover {
                    path {
                      fill: ${props => props.theme.buttercupYellow};
                    }
                  }
                `}
              >
                <HeartNoFill fill='#fff'/>
              </ReactHeaderA>
            </ReactHeaderFixedBottomBtnDiv>
          </ReactHeaderFixedBtnContainer>
          <ReactHeaderFixedBottomCartDiv>
            <ReactHeaderFixedBottomCartA
              href='/cart'
              aria-label='cart'
              data-tracking='header|mobile-cart'
              styles={css`
                position: relative; 
                width: 40px;
                height: 40px;
                background-color: white;
                border-radius: 3.1rem;
                &:hover {
                  circle {
                    fill: ${props => props.theme.buttercupYellow};
                  }
                }
              `}
            >
              <Cart height='40' width='40'/>
              <CartCountDiv
                className='cart__count bypassInterstitialPage'
                styles={cartCount > 99 ? css`
                  font-size: 1.2rem;
                  height: 2.5rem;
                  width: 2.5rem;
                  top: -0.3rem;
                ` : ''}
              >
                {cartCount > 99 ? '99+' : cartCount}
              </CartCountDiv>
            </ReactHeaderFixedBottomCartA>
          </ReactHeaderFixedBottomCartDiv>
          <ReactHeaderFixedBtnContainer>
            <ReactHeaderFixedBottomBtnDiv styles={css`justify-content: flex-end;`}>
              {isLoggedIn ?
                <ReactHeaderFixedBottomBtn
                  data-testid='accountBtmMenu'
                  aria-label='my account'
                  onClick={() => {triggerDropdown(1)}}
                  onMouseEnter={isDesktop ? () => {triggerDropdown(1, 'hover')} : () => {}}
                  styles={css`
                    &:hover {
                      path {
                        fill: ${props => props.theme.buttercupYellow};
                      }
                    }
                  `}
                >
                  <PersonWithCircle fill='#fff'/>
                </ReactHeaderFixedBottomBtn>
                :
                <ReactHeaderFixedBottomBtn
                  onClick={loginSliderHandler}
                  data-tracking='header|login'
                  aria-label='login'
                  styles={css`
                    &:hover {
                      path {
                        fill: ${props => props.theme.buttercupYellow};
                      }
                    }
                  `}
                >
                  <PersonWithCircle fill='#fff'/>
                </ReactHeaderFixedBottomBtn>
              }
            </ReactHeaderFixedBottomBtnDiv>
            <ReactHeaderFixedBottomBtnDiv styles={css`justify-content: flex-end; padding-right: 2rem;`}>
              <ReactHeaderFixedBottomBtn
                data-testid='helpBtmMenu'
                aria-label='help'
                onClick={() => {triggerDropdown(3)}}
                onMouseEnter={isDesktop ? () => {triggerDropdown(3, 'hover')} : () => {} }
                styles={css`
                  &:hover {
                    path {
                      fill: ${props => props.theme.buttercupYellow};
                    }
                  }
                `}
              >
                <QuestionWithCircle fill='#fff'/>
              </ReactHeaderFixedBottomBtn>
            </ReactHeaderFixedBottomBtnDiv>
          </ReactHeaderFixedBtnContainer>
        </ReactHeaderFixedDiv>
        {showMenuType !== 0 &&
          <HeaderMenuDropdown
            menuType={showMenuType}
            closeHandler={closeHandler}
            isFixedBottom={true}
            emailOpenHandler={emailOpenHandler}
          />
        }
      </ReactHeaderFixedBottomRelative>
    </ReactHeaderFixedBottomContainer>
  )
}

const ReactHeaderFixedBottomContainer = styled.div`
  @media (max-width: 1279px) {
    display: flex;
    flex-wrap: wrap;
    background-color: ${props => props.theme.turtleGreen};
    width: 100%;
    position: fixed;
    z-index: 99999999; //Feedback button is at 99999990, needs to be above that
    bottom: 0;
  }
  @media (min-width: 1280px) {
    display: none;
  }
`

const ReactHeaderFixedBottomRelative = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
`

const ReactHeaderFixedDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  ${props => props.styles};
`

const ReactHeaderFixedBtnContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: calc(50% - 32px);
`

const CartCountDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.8rem;
  width: 1.8rem;
  top: -0.3rem;
  left: 3.4rem;
  font-size: 1.4rem;
  color: ${props => props.theme.black};
  line-height: 1.6rem;
  background-color: ${props => props.theme.buttercupYellow};
  border: 1px solid ${props => props.theme.primary};
  border-radius: 3rem;
  position: absolute;
  text-align: center;
  ${props => props.styles};
`


const ReactHeaderFixedBottomBtn = styled(TransparentButton)`
  border: none;
  ${props => props.styles};
`

const ReactHeaderFixedBottomCartDiv = styled.div`
  display: flex;
  width: 64px;
  justify-content: center;
  align-items: center;
`

const ReactHeaderFixedBottomBtnDiv = styled.div`
  display: flex;
  width: 50%;
  align-items: center;
  ${props => props.styles};
`

const ReactHeaderA = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 1.2rem;
  padding: 0;
  border: none;
  width: 3rem;
  height: 3rem;
  transition: none;
  text-decoration: none;
  &:hover {
    border-radius: 2rem;
    transition: none;
    text-decoration: none;
    @media (min-width: 1280px) {
      background-color: ${props => props.theme.primary};
      path {
        fill: ${props => props.theme.white};
      }
      circle {
        fill: ${props => props.theme.primary};
      }
    }
  }
  ${props => props.styles};
`

const ReactHeaderFixedBottomCartA = styled.a`
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
  ${props => props.styles};
`
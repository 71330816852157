import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'
import { SmMax, SmMin } from '../../Responsive/Responsive'
import { CarouselContainer } from '../../CarouselContainer/CarouselContainer'
import { BlackLink } from '../../_styled/Link'
import { Instances } from '../../Instances/Instances'

export const NoResultsApp = ({ type = '' }) => {
  useEffect(() => {
    if (type === '404') {
      if (window.utag === undefined) {
        new Promise(function (resolve, reject) {
          var tag = document.createElement('script')
          tag.src = window.currentEnvironment
          tag.async = true
          tag.onload = function () {
            resolve()
          }
          var firstScriptTag = document.getElementsByTagName('script')[0]
          firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
        }).then(function () {
          window.utag.view(window.utag_data)
        })
      } else {
        window.utag.view(window.utag_data)
      }
    }
  }, [])
  const ul = (
    <>
      <NoResultsH2>Not sure where to go? Here are some paths to help get you back on track:</NoResultsH2>
      <BoxesAUl>
        <BoxesLi><BoxesA href='/collections/top-rated-products'>Top Rated Products</BoxesA></BoxesLi>
        <BoxesLi><BoxesA href='/morewaystobuy/On+Sale+Now/q?lp=t'>On Sale</BoxesA></BoxesLi>
        <BoxesLi><BoxesA href='/morewaystobuy/Buy+One%2C+Get+One/q'>BOGO</BoxesA></BoxesLi>
        <BoxesLi><BoxesA href='/swanson-health-products'>Swanson Health Products</BoxesA></BoxesLi>
        <BoxesLi><BoxesA href='/Brands'>Shop All Brands</BoxesA></BoxesLi>
        <BoxesLi><BoxesA href='/help/company-information/company-information-faqs/contact-us.html'>Contact Us</BoxesA></BoxesLi>
      </BoxesAUl>
    </>
  )

  return (
    <NoResultsSection>
      <Instances />
      <SmMax>
        <NoResultsH1>Ope! Sorry ‘Bout That</NoResultsH1>
      </SmMax>
      <GrayBackgroundDiv className='404-link-tracking'>
        <ImgDiv>
          <NotFoundImg src='https://media.swansonvitamins.com/images/common/error/2024-404-error-image.jpg' alt='404 Page Not Found - image of a confused person' />
          <NotFoundInfoDiv>
            <SmMin>
              <NoResultsH1>Ope! Sorry ‘Bout That</NoResultsH1>
            </SmMin>
            <ExplanationSmall>
              We searched high and low but couldn’t find anything that matched.
            </ExplanationSmall>
            <SmMin>
              {ul}
            </SmMin>
            <small>Questions? <BlackLink2 href='https://www.swansonvitamins.com/help/company-information/company-information-faqs/contact-us.html'>Contact Us</BlackLink2></small>
            {type === '404' &&
              <small>Error Code: 404</small>
            }
          </NotFoundInfoDiv>
        </ImgDiv>
        <SmMax>
          <div>
            {ul}
          </div>
        </SmMax>
      </GrayBackgroundDiv>
      <CarouselContainer
        gqlType='emptySearch'
        index={1}
        containerDivStyles={css`
          width: 100%;
        `}
        parentDivStyles={css`
          width: 100%;
          margin-bottom: 0;
        `}
        carouselContainerStyles={css`
          justify-content: space-evenly;
          margin-bottom: 3rem; 
          width: 100%;
        `}
      />
      <CarouselContainer
        gqlType='emptySearch'
        index={0}
        containerDivStyles={css`
          width: 100%;
        `}
        parentDivStyles={css`
          width: 100%;
            margin-bottom: 0;
        `}
        carouselContainerStyles={css`
          justify-content: space-evenly;
          margin-bottom: 3rem; 
          width: 100%;
        `}
      />
    </NoResultsSection>
  )
}

const NoResultsSection = styled.section`
  display: flex;
  justify-content: center;
  max-width: 140rem;
  flex-direction: column;
  @media (min-width: 1280px) {
    margin: 2rem auto;
  }
  @media (max-width: 1279px) {
    margin: 16rem auto;
  }
`

const NotFoundImg = styled.img`
  max-width: 24rem;
  margin-right: 2rem;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    max-width: 20rem;
  }
`

const ImgDiv = styled.div`
  display: flex;
  align-items: center;
`

const NoResultsH1 = styled.h1`
  font-size: 1.8rem;
  font-weight: normal;
`

const ExplanationSmall = styled.small`
  font-size: 1.4rem;
`

const NoResultsH2 = styled.h2`
  font-size: 1.5rem;
  margin-top: 2rem;
  font-weight: normal;
`

const GrayBackgroundDiv = styled.div`
  background-color: #EEEFEF;
  display: flex;
  margin: .6rem;
  flex-direction: column;
`

const NotFoundInfoDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`

const BoxesAUl = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
`
const BoxesLi = styled.li`
  height: 5rem;
  display: inline-flex;
  align-items: center;
`

const BoxesA = styled.a`
  border: .1rem solid ${props => props.theme.darkestOrange};
  color: ${props => props.theme.white};
  padding: .5rem 2rem;
  background-color: ${props => props.theme.darkestOrange};
  margin:.8rem;
  border-radius: .8rem;
  text-decoration: none;
`

const BlackLink2 = styled(BlackLink)`
  text-decoration: underline;
`
